import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	emailVerified: false,
	storeSetUp: false,
	productsAdded: false,
	settlementAccount: false,
	profileIsComplete: false,
};

const profileCompleteSlice = createSlice({
	name: 'profileComplete',
	initialState,
	reducers: {
		setProfileComplete: (state, action) => {
			const {
				emailVerified,
				storeSetUp,
				productsAdded,
				settlementAccount,
				profileIsComplete,
			} = action.payload;
			state.emailVerified = emailVerified;
			state.storeSetUp = storeSetUp;
			state.productsAdded = productsAdded;
			state.settlementAccount = settlementAccount;
			state.profileIsComplete = profileIsComplete;
		},
		clearProfileComplete: (state) => {
			state.emailVerified = false;
			state.storeSetUp = false;
			state.productsAdded = false;
			state.settlementAccount = false;
			state.profileIsComplete = false;
		},
	},
});

export const { setProfileComplete, clearProfileComplete } =
	profileCompleteSlice.actions;
export default profileCompleteSlice.reducer;
