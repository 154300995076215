import React from 'react';
import InputField from '../input_field';
const Homegoods = ({ productData, handleProductDataChange }) => {
	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<InputField
					label='Room Type'
					value={productData.attributes.roomtype || 'Living Room'}
					handleValue={(value) =>
						handleProductDataChange('roomtype', value, true)
					}
					type='select'
					options={[
						{ label: 'Living Room', value: 'Living Room' },
						{ label: 'Dining Room', value: 'Dining Room' },
						{ label: 'Bedroom', value: 'Bedroom' },
						{ label: 'Kitchen', value: 'Kitchen' },
						{ label: 'Study', value: 'Study' },
					]}
				/>
			</div>

			<div className='flex flex-col gap-3'>
				<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500 '>
					Weight
				</label>
				<input
					type='text'
					placeholder='Ex. 50kg'
					className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
				/>
			</div>
			<p>Dimensions</p>
			<div className='flex flex-col gap-3'>
				<InputField
					label='Measurement'
					value={productData.attributes.measurement || 'cm'}
					handleValue={(value) =>
						handleProductDataChange('measurement', value, true)
					}
					type='select'
					options={[
						{ label: 'cm', value: 'cm' },
						{ label: 'inches', value: 'inches' },
						{ label: 'feet', value: 'feet' },
						{ label: 'yard', value: 'yard' },
					]}
				/>
			</div>

			<div className='flex gap-3'>
				<div className='flex flex-col gap-3'>
					<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
						Length
					</label>
					<input
						type='text'
						placeholder='Ex. 50cm'
						className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
					/>
				</div>
				<div className='flex flex-col gap-3'>
					<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500 '>
						Width
					</label>
					<input
						type='text'
						placeholder='Ex. 50cm'
						className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
					/>
				</div>
				<div className='flex flex-col gap-3'>
					<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500 '>
						Height
					</label>
					<input
						type='text'
						placeholder='Ex. 50cm'
						className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
					/>
				</div>
			</div>
		</div>
	);
};

export default Homegoods;
