export const paths = {
	HOME: '/',
	SIGNUP: '/create-account',
	SIGNIN: '/signin',
	PROFILE: '/profile',

	// Dashboard
	OVERVIEW: '/overview',
	ONBOARDING: '/onboarding',
	
	//Store Routes
	STORE: '/store',
	PRODUCTS: '/store/products',
	PRODUCTDETAILS: '/store/products/:productId',
	COUPONS: '/store/coupons',
	CATEGORIES: '/store/categories',

	//Order Routes
	ORDERS: '/orders',
	ACTIVEORDERS: '/orders/active-orders',
	ORDERHISTORY: '/orders/order-history',

	//Customers
	CUSTOMERS: '/customers',
	ALLCUSTOMERS: '/customers/customers',

	// Other paths
	TRACKING: '/order/:orderId',
	CHECKOUT: '/store/:storeName/:storeId/checkout',
	VERIFY: '/verify-account',
	SECURE: '/secure-account',
	BUSINESS: '/create-business',
};
