const GetLocationService = async (getCurrentUserLocation, retries = 3) => {
	if (!navigator.onLine) {
		// console.error('No internet connection available');
		throw new Error('No internet connection available');
	}

	function handleGeolocationError(error) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				throw new Error('User denied location access');
			case error.POSITION_UNAVAILABLE:
				throw new Error('Location information is unavailable');
			case error.TIMEOUT:
				throw new Error('Geolocation timed out');
			default:
				throw new Error(`Geolocation error: ${error.message}`);
		}
	}

	return new Promise((resolve, reject) => {
		const attemptLocationFetch = (attempt) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const { latitude, longitude } = position.coords;
					try {
						const response = await getCurrentUserLocation(
							latitude,
							longitude,
						);
						if (response.status === 200) {
							resolve(response.data || position.coords);
						} else {
							reject(new Error('Error fetching location data'));
						}
					} catch (error) {
						reject(error);
					}
				},
				(error) => {
					if (attempt < retries) {
						// console.warn(
						// 	`Retrying location fetch... Attempt ${attempt + 1}`,
						// );
						setTimeout(
							() => attemptLocationFetch(attempt + 1),
							10000,
						);
					} else {
						// console.error('Error getting location:', error.message);
						handleGeolocationError(error);
					}
				},
				{ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 },
			);
		};

		attemptLocationFetch(0);
	});
};

export default GetLocationService;
