const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
	return (
		<div className='flex items-center justify-between space-x-4 h-[28px]'>
			<span className='text-[16px] text-[#101B10] h-full flex items-center'>
				Page {currentPage} of {totalPages}
			</span>
			<div className='space-x-2 flex items-center h-full'>
				<button
					onClick={() => handlePageChange(currentPage - 1)}
					disabled={currentPage === 1}
					className={`px-3 py-1 h-full flex items-center justify-center rounded-lg ${
						currentPage === 1 ? 'text-[#AAB1AF]' : 'text-[#101B10]'
					}`}>
					&lt;
				</button>
				{Array.from({ length: totalPages }, (_, index) => (
					<button
						key={index}
						onClick={() => handlePageChange(index + 1)}
						className={`px-3 py-1 h-full flex items-center justify-center border border-[#E3E3E3] rounded-lg ${
							currentPage === index + 1
								? 'bg-basegreen text-[#ffffff]'
								: 'text-[#7B8783] bg-[#F0F0F0]'
						}`}>
						{index + 1}
					</button>
				))}
				<button
					onClick={() => handlePageChange(currentPage + 1)}
					disabled={currentPage === totalPages}
					className={`px-3 py-1 h-full flex items-center justify-center rounded-lg ${
						currentPage === totalPages
							? 'text-[#AAB1AF]'
							: 'text-[#101B10]'
					}`}>
					&gt;
				</button>
			</div>
		</div>
	);
};

export default Pagination;
