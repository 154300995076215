import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataDisplayRenderer from '../components/DataDisplay';
import Layout from '../../../Layout';
import { Fa42Group } from 'react-icons/fa6';
import DeleteModal from '../components/deleteModal';
import { useAPI } from '../../../apis/api_context';
import { toast } from 'sonner';
import ShareProductModal from '../components/shareProductModal';
import { useMemo } from 'react';
import FilterWithDropdown from '../components/filterWithDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../store/userSlice';

const Store = () => {
	const dispatch = useDispatch();
	const { store } = useSelector((state) => state.user);
	const apiservice = useAPI();
	const navigate = useNavigate();
	const location = useLocation();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null);
	const [deletionType, setDeletionType] = useState(null);
	const [isShareModalOpen, setShareModalOpen] = useState(false);
	const [itemToShare, setItemToShare] = useState(null);
	const [shareType, setShareType] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState('All Products');

	const fetchStore = useCallback(async () => {
		try {
			setLoading(true);
			const response = await apiservice.getStore();

			dispatch(setUserData({ store: response }));
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}, [apiservice, dispatch]);

	useEffect(() => {
		fetchStore();
	}, [fetchStore]);

	const openDeleteModal = (item, type) => {
		setItemToDelete(item);
		setDeletionType(type);
		setDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
		setItemToDelete(null);
		setDeletionType(null);
	};

	const openShareModal = (item, type) => {
		setItemToShare(item);
		setShareType(type);
		setShareModalOpen(true);
	};

	const closeShareModal = () => {
		setShareModalOpen(false);
		setItemToShare(null);
		setShareType(null);
	};

	const confirmDelete = async () => {
		if (deletionType === 'product') {
			await deleteProduct(itemToDelete.id);
		} else if (deletionType === 'coupon') {
			await deleteCoupon(itemToDelete.id);
		} else if (deletionType === 'category') {
			await deleteCategory(itemToDelete.id);
		}
		closeDeleteModal();
		fetchStore();
	};

	const deleteProduct = async (productId) => {
		try {
			const response = await apiservice.deleteProduct(productId);
			if (response.status === 200) {
				toast.success('Product deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			//
			toast.error('Error deleting product. Please try again.');
		}
	};
	const deleteCoupon = async (couponId) => {
		try {
			const response = await apiservice.deleteCoupon(couponId);
			if (response.status === 200) {
				toast.success('Coupon deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			toast.error('Error deleting coupon. Please try again.');
		}
	};
	const deleteCategory = async (categoryId) => {
		try {
			const response = await apiservice.deleteCategory(categoryId);
			if (response.status === 200) {
				toast.success('Category deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			toast.error('Error deleting category. Please try again.');
		}
	};
	const couponActions = (coupon) => [
		{
			label: 'Delete',
			onClick: () => openDeleteModal(coupon, 'coupon'),
		},
		{
			label: 'Share',
			onClick: () => openShareModal(coupon, 'coupon'),
		},
	];
	const categoryActions = (category) => [
		{
			label: 'Delete',
			onClick: () => openDeleteModal(category, 'category'),
		},
		{
			label: 'Share',
			onClick: () => openShareModal(category, 'category'),
		},
	];
	const productActions = (product) => [
		{
			label: 'Delete',
			onClick: () => openDeleteModal(product, 'product'),
		},
		{
			label: 'Share',
			onClick: () => {
				openShareModal(product, 'product');
			},
		},
	];

	const tabs = useMemo(() => {
		const products =
			store.categories
				?.map((category) =>
					category.products.map((product) => ({
						id: product._id,
						name: product.name,
						description: product.description,
						unitPrice: product.unitPrice,
						availableQuantity: product.availableQuantity,
						images: product.images,
						categoryName: category.name,
					})),
				)
				.flat() || [];

		const filteredProducts =
			selectedCategory === 'All Products'
				? products
				: products.filter(
						(product) => product.categoryName === selectedCategory,
				  );

		const categories =
			store.categories?.map((category) => ({
				id: category._id,
				name: category.name,
				products: category.products.length,
			})) || [];

		//Dropdown to sort products based on categories and all products
		const productItems = [
			{ id: 'all-products', name: 'All Products' },
			...categories,
		];

		return [
			{
				name: 'Products',
				headers: [
					'Name',
					'Description',
					'Unit Price',
					'Available Quantity',
				],
				data: filteredProducts,
				view: 'card',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have not added a product yet
						</p>
					</div>
				),
				filter: (
					<FilterWithDropdown
						dropdownItems={productItems}
						selectedOption={selectedCategory}
						setSelectedOption={setSelectedCategory}
						title='All Products'
						onFilterChange={setSelectedCategory}
					/>
				),
			},
			//TODO: Propagate Coupon View, Uncomment this.

			{
				name: 'Categories',
				headers: ['Category', 'Products'],
				data: categories,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have not added a category yet
						</p>
					</div>
				),
			},
		];
	}, [store.categories, selectedCategory]);

	const [activeTab, setActiveTab] = useState(() => {
		const savedTab = localStorage.getItem('activeTab');
		return savedTab || tabs[0].name;
	});

	const [currentPage, setCurrentPage] = useState(() => {
		const savedPage = localStorage.getItem('currentPage');
		return savedPage ? parseInt(savedPage, 10) : 1;
	});

	const activeTabData = tabs.find((tab) => tab.name === activeTab) || {};

	useEffect(() => {
		localStorage.setItem('activeTab', activeTab);
		localStorage.setItem('currentPage', currentPage.toString());

		const path = `/store/${activeTab.toLowerCase()}`;
		if (location.pathname !== path) {
			navigate(path);
		}
	}, [activeTab, currentPage, navigate, location.pathname]);

	useEffect(() => {
		const pathTab = location.pathname.split('/')[2];
		const matchingTab = tabs.find(
			(tab) => tab.name.toLowerCase() === pathTab,
		);
		if (matchingTab) {
			setActiveTab(matchingTab.name);
		}

		return () => {
			localStorage.removeItem('activeTab');
			localStorage.removeItem('currentPage');
		};
	}, [location.pathname, tabs]);

	const handleCardClick = (product) => {
		navigate(`/store/products/${product.id}`);
	};

	return (
		<Layout>
			<div className='flex flex-col h-[86.5vh]'>
				<div className='flex-grow h-full overflow-hidden'>
					{loading ? (
						<div className='flex items-center justify-center h-full'>
							<p className='text-xl font-semibold text-gray-500'>
								Loading Store Info...
							</p>
						</div>
					) : (
						<DataDisplayRenderer
							tabs={tabs}
							defaultView={activeTabData.view}
							setActiveTab={setActiveTab}
							activeTab={activeTab}
							onCardClick={(item) => handleCardClick(item)}
							onTableClick={(item) => {}}
							onActionClick={(item) =>
								activeTab === 'Products'
									? productActions(item)
									: activeTab === 'Coupons'
									? couponActions(item)
									: categoryActions(item)
							}
							emptyStateMessage={activeTabData.emptyStateMessage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							filterComponent={activeTabData.filter}
						/>
					)}
				</div>
				<DeleteModal
					isOpen={deleteModalOpen}
					onClose={closeDeleteModal}
					onConfirm={confirmDelete}
					itemName={itemToDelete ? itemToDelete.name : ''}
				/>
				{isShareModalOpen && (
					<ShareProductModal
						closeShareModal={closeShareModal}
						selectedProduct={itemToShare}
						type={shareType}
					/>
				)}
			</div>
		</Layout>
	);
};

export default Store;
