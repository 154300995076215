import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataDisplayRenderer from '../components/DataDisplay';
import Layout from '../../../Layout';
import { Fa42Group } from 'react-icons/fa6';
import { useAPI } from '../../../apis/api_context';
import { useMemo } from 'react';

const Customers = () => {
	const apiservice = useAPI();
	const navigate = useNavigate();
	const location = useLocation();
	const [customersList, setCustomersList] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchCustomers = useCallback(async () => {
		try {
			setLoading(true);
			const response = await apiservice.getCustomers();
			setCustomersList(response.data);
		} catch (error) {
			// // console.error(error);
		} finally {
			setLoading(false);
		}
	}, [apiservice]);

	useEffect(() => {
		fetchCustomers();
	}, [fetchCustomers]);

	const tabs = useMemo(() => {
		const customers =
			customersList?.map((customer) => ({
				id: customer._id,
				name: customer.name,
				email: customer.email,
				phone: customer.phone_number,
				orderCount: customer.orders.length,
			})) || [];

		return [
			{
				name: 'Customers',
				headers: ['Name', 'Email', 'Phone', 'Order Count'],
				data: customers,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have no customers yet
						</p>
					</div>
				),
			},
		];
	}, [customersList]);

	const [activeTab, setActiveTab] = useState(() => {
		const savedTab = localStorage.getItem('activeTab');
		return savedTab || tabs[0].name;
	});

	const [currentPage, setCurrentPage] = useState(() => {
		const savedPage = localStorage.getItem('currentPage');
		return savedPage ? parseInt(savedPage, 10) : 1;
	});

	const activeTabData = tabs.find((tab) => tab.name === activeTab) || {};

	useEffect(() => {
		localStorage.setItem('activeTab', activeTab);
		localStorage.setItem('currentPage', currentPage.toString());

		const path = `/customers/${activeTab.toLowerCase()}`;
		if (location.pathname !== path) {
			navigate(path);
		}
	}, [activeTab, currentPage, navigate, location.pathname]);

	useEffect(() => {
		const pathTab = location.pathname.split('/')[2];
		const matchingTab = tabs.find(
			(tab) => tab.name.toLowerCase() === pathTab,
		);
		if (matchingTab) {
			setActiveTab(matchingTab.name);
		}

		return () => {
			localStorage.removeItem('activeTab');
			localStorage.removeItem('currentPage');
		};
	}, [location.pathname, tabs]);

	const handleCardClick = (customer) => {
		navigate(`/store/customers/${customer.id}`);
	};

	return (
		<Layout>
			<div className='flex flex-col h-[86.5vh]'>
				<div className='flex-grow h-full overflow-hidden'>
					{loading ? (
						<div className='flex items-center justify-center h-full'>
							<p className='text-xl font-semibold text-gray-500'>
								Loading Customers...
							</p>
						</div>
					) : (
						<DataDisplayRenderer
							tabs={tabs}
							defaultView={activeTabData.view}
							setActiveTab={setActiveTab}
							activeTab={activeTab}
							onCardClick={(item) => handleCardClick(item)}
							onTableClick={(item) => {
								// // console.log('Table row clicked:', item)
							}}
							emptyStateMessage={activeTabData.emptyStateMessage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default Customers;
