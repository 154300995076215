import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import authReducer from './authSlice';
import userReducer from './userSlice';
import navigationReducer from './navigationSlice';
import profileCompleteReducer from './profileCompleteSlice';

const rootPersistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'user', 'navigation', 'profileComplete'],
};

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	navigation: navigationReducer,
	profileComplete: profileCompleteReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});

export const persistor = persistStore(store);
