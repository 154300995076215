import axios from 'axios';
import { store } from '../store';

const isTestEnv = process.env.REACT_APP_ENV === 'test';
const baseURL = isTestEnv
	? 'http://localhost:1222/api'
	: 'https://' + process.env.REACT_APP_CENTRAL_SERVER + '/api';

export const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(
	(config) => {
		const state = store.getState();
		const accessToken = state.auth.accessToken;

		if (accessToken) {
			config.headers['jwt'] = `${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);
