import React, { useState } from 'react';
import Dropdown from './dropdown';
import filter from '../../../assets/images/filter.svg';

const FilterWithDropdown = ({
	selectedOption,
	setSelectedOption,
	dropdownItems,
	title,
	onFilterChange,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState({});

	const handleSelect = (option) => {
		setSelectedOption(option);
		if (onFilterChange) {
			onFilterChange(option);
		}
		handleCloseDropdown();
	};

	const handleToggleDropdown = (e) => {
		setDropdownPosition({
			top: e.target.offsetTop + e.target.offsetHeight,
			left: e.target.offsetLeft,
		});
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	return (
		<div className='relative inline-block'>
			{/* Button to toggle dropdown */}
			<div
				className='flex items-center gap-6'
				onClick={handleToggleDropdown}>
				<button className='flex justify-center items-center'>
					<img
						src={filter}
						alt='Filter icon'
						className='w-[24px] h-[24px]'
					/>
				</button>
				<button
					className=''
					onClick={handleToggleDropdown}>
					{selectedOption || title}
				</button>
			</div>
			{/* Render Dropdown if open */}
			{isDropdownOpen && (
				<Dropdown
					actions={dropdownItems.map((item) => ({
						label: item.name,
						onClick: () => handleSelect(item.name),
					}))}
					onClose={handleCloseDropdown}
					position={`top-[${dropdownPosition.top}px] left-[${dropdownPosition.left}px]`}
					width='min-w-[150px]'
				/>
			)}
		</div>
	);
};

export default FilterWithDropdown;
