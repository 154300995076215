import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import SizeModal from './sizemodal';
import InputField from '../input_field';
import { FaTimes } from 'react-icons/fa';
import Button from '../button';

const Clothing = ({
	sizes,
	setSizes,
	colors,
	setColors,
	productData,
	handleProductDataChange,
}) => {
	const [newSize, setNewSize] = useState('');
	const [newColor, setNewColor] = useState('#FFFFFF');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isColorModalOpen, setIsColorModalOpen] = useState(false);
	const [selectedColor, setSelectedColor] = useState('#FFFFFF');
	const [selectedSizes, setSelectedSizes] = useState([]);
	const [colorName, setColorName] = useState('');

	const handleColorClick = (color) => {
		setSelectedColor(color);
		handleProductDataChange('colors', color, true);
	};

	const handleSizeClick = (size) => {
		if (size === 'Custom') {
			setIsSizeModalOpen(true);
		} else {
			const updatedSizes = selectedSizes.includes(size)
				? selectedSizes.filter((s) => s !== size)
				: [...selectedSizes, size];
			setSelectedSizes(updatedSizes);
			handleProductDataChange('sizes', updatedSizes, true);
		}
	};

	const handleAddColor = () => {
		if (newColor) {
			const updatedColors = [...colors, newColor];
			setColors(updatedColors);
			setIsColorModalOpen(false);
			handleProductDataChange('colors', updatedColors, true);
		}
	};

	const handleAddSize = (category) => {
		if (newSize) {
			const updatedSizes = {
				...sizes,
				[category]: [...(sizes[category] || []), newSize],
			};
			setSizes(updatedSizes);
			setNewSize('');
			setIsSizeModalOpen(false);
			handleProductDataChange('sizes', updatedSizes[category], true);
		}
	};

	const fetchColorName = async (colorHex) => {
		try {
			const response = await fetch(
				`https://api.color.pizza/v1/?values=${colorHex}`,
			);
			const data = await response.json();
			const colorData = data.colors[0];
			setColorName(colorData.name);
		} catch (error) {
			// console.error('Error fetching color name:', error);
		}
	};

	useEffect(() => {
		if (newColor) {
			fetchColorName(newColor.replace('#', ''));
		}
	}, [newColor]);

	const handleColorInputChange = (value) => {
		if (value.startsWith('#') && value.length <= 7) {
			setNewColor(value);
			handleProductDataChange('colors', value, true);
		}
	};

	const handleResetColor = () => {
		const defaultColor = '#FFFFFF';
		setNewColor(defaultColor);
		setSelectedColor(defaultColor);
		handleProductDataChange('colors', defaultColor, true);
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Sizes (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{sizes.cloth.map((size, index) => (
						<button
							type='button'
							key={index}
							onClick={() => handleSizeClick(size)}
							className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
								selectedSizes.includes(size)
									? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
									: 'border-[1px] border-secondary bg-white text-gray-500'
							}`}>
							{size}
						</button>
					))}
				</div>
			</div>

			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Colors (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{colors.map((color, index) => (
						<div
							key={index}
							className={`w-8 h-8 cursor-pointer border rounded-md ${
								color === selectedColor
									? 'border-2 border-[#dfdfdf]'
									: 'border border-gray-300'
							}`}
							style={{ backgroundColor: color }}
							onClick={() => handleColorClick(color)}
						/>
					))}
					<button
						type='button'
						onClick={() => setIsColorModalOpen(true)}
						className='text-[14px] text-basegreen'>
						+add colors
					</button>
				</div>
			</div>

			<InputField
				label='Gender'
				value={productData.attributes.gender || 'Male'}
				handleValue={(value) =>
					handleProductDataChange('gender', value, true)
				}
				type='select'
				options={[
					{ label: 'Male', value: 'Male' },
					{ label: 'Female', value: 'Female' },
					{ label: 'Unisex', value: 'Unisex' },
				]}
			/>

			{isSizeModalOpen && (
				<SizeModal
					isOpen={isSizeModalOpen}
					onClose={() => setIsSizeModalOpen(false)}
					newSize={newSize}
					setNewSize={setNewSize}
					handleAddSize={() => handleAddSize('cloth')}
					title='Add New Size'
					label='Input Cloth Size'
					placeholder='4XL'
					buttonText='Add Size'
				/>
			)}

			{isColorModalOpen && (
				<div
					className='fixed inset-0 flex items-center justify-center bg-primary bg-opacity-50'
					onClick={() => setIsColorModalOpen(false)}>
					<div
						className='bg-white p-4 rounded-lg w-[30vw]'
						onClick={(e) => e.stopPropagation()}>
						<div className='flex justify-between mb-8 items-center'>
							<h1 className='text-[21px] text-primary'>
								Add New Color
							</h1>
							<button onClick={() => setIsColorModalOpen(false)}>
								<FaTimes />
							</button>
						</div>
						<div className='flex justify-center items-center w-48 h-48 rounded overflow-hidden mx-auto'>
							<HexColorPicker
								color={newColor}
								onChange={setNewColor}
								className='border'
							/>
						</div>

						<div className='mt-4'>
							<h4 className='text-gray-500 font-[500] text-[14px]'>
								Selection
							</h4>
							<div className='border-t border-b flex justify-between w-full  py-[12px] px-[12px] border-borderNeutral'>
								<div className='flex gap-1 items-center justify-center'>
									<div
										className='w-8 h-8 border rounded-lg'
										style={{ backgroundColor: newColor }}
									/>
									<input
										type='text'
										value={newColor}
										onChange={(e) =>
											handleColorInputChange(
												e.target.value,
											)
										}
										className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500 focus:outline-none focus:border-none border-none focus:ring-0 focus:shadow-none '
									/>
								</div>

								<button
									type='button'
									onClick={handleResetColor}
									className='text-basegreen font-[500] text-[14px] leading-[15px] -tracking-[3%]'>
									Reset
								</button>
							</div>
							<p className='mt-4 text-[14px] text-gray-500'>
								Color Name: {colorName}
							</p>
						</div>
						<Button
							label={'Add Color'}
							onClick={handleAddColor}
							type='primary'
							className='w-full mt-8'
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Clothing;
