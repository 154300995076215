const SubscriptionBanner = ({ tier, className = '', onClick }) => {
	return (
		<div
			className={`
          flex items-center gap-2 px-4 py-2.5 h-[48px] bg-gray-100 hover:bg-gray-200 rounded-lg border border-gray-200 tier-gray-900 ${className}
        `}
			onClick={onClick}>
			<i className='fi fi-sr-gem h-6 w-6 inline-flex items-center justify-center tier-basegreen'></i>
			<div className='flex items-center space-x-2'>
				<p>{tier}</p>{' '}
				<div className='h-1 w-1 rounded-full bg-secondary'></div>
				<p className='text-sm text-basegreen cursor-pointer'>
					{tier !== 'premium' && 'upgrade'}
				</p>
			</div>
		</div>
	);
};

export default SubscriptionBanner;
