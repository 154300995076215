import React from 'react';

const Card = ({ icon, title, description, onClick }) => (
	<div
		className='bg-gray-50 p-6 rounded-xl relative max-w-xs cursor-pointer'
		onClick={onClick}>
		<div className='flex justify-between items-start'>
			<div
				className={`w-10 h-10 rounded-full flex items-center justify-center ${icon.bgColor}`}>
				{icon.component}
			</div>
		</div>

		<h2 className='font-medium mt-4 mb-2'>{title}</h2>
		<p className='text-gray-500 text-[14px] leading-snug'>{description}</p>
	</div>
);

export default Card;
