import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './api_helper';
import { toast } from 'sonner';
import { useDispatch, useSelector } from 'react-redux';
import { setMerchantId, setAccessToken, clearAuth } from '../store/authSlice';
import { setUserData, clearUserData } from '../store/userSlice';
import { paths } from '../routes';
import { setProfileComplete } from '../store/profileCompleteSlice';

const AuthContext = createContext();

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export const AuthProvider = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { merchantId } = useSelector((state) => state.auth);

	const mapProfileData = (profileData) => ({
		emailVerified: profileData.hasVerifiedEmail,
		storeSetUp: profileData.hasStore,
		productsAdded: profileData.hasProducts,
		settlementAccount: profileData.hasSettlementAccount,
		profileIsComplete: profileData.isMerchantProfileVerified,
	});

	const updateUserState = useCallback(
		(merchantId, token) => {
			dispatch(setMerchantId(merchantId));
			dispatch(setAccessToken(token));
		},
		[dispatch],
	);

	const checkAndSetProfileCompletion = useCallback(
		async (merchantId) => {
			const profileData = await api.get(
				`/merchant/${merchantId}/checkProfile`,
			);

			// console.log(profileData);
			const mappedProfileData = mapProfileData(profileData.data.data);
			// console.log(mappedProfileData);
			dispatch(setProfileComplete(mappedProfileData));
			return mappedProfileData;
		},
		[dispatch],
	);

	const refreshUserData = useCallback(async () => {
		try {
			const response = await api.get(`/merchant/${merchantId}`);
			const data = response.data.data;
			dispatch(setUserData(data));
			// console.log('Refresh User Data: ', data);

			checkAndSetProfileCompletion(merchantId);

			return data;
		} catch (error) {
			toast.error('Failed to refresh user data.');
			throw error;
		}
	}, [checkAndSetProfileCompletion, dispatch, merchantId]);

	const signupMerchant = useCallback(
		async (firstName, lastName, phoneNumber, email, password) => {
			try {
				const response = await api.post('/merchant/signup', {
					firstName,
					lastName,
					phoneNumber,
					email,
					password,
				});
				toast.success('Signup Successful');
				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[],
	);

	const getUserData = useCallback(
		async (merchantId) => {
			try {
				const response = await api.get(`/merchant/${merchantId}`);
				const data = response.data.data;
				dispatch(setUserData(data));
				checkAndSetProfileCompletion(merchantId);
				return data;
			} catch (error) {
				throw error;
			}
		},
		[checkAndSetProfileCompletion, dispatch],
	);

	const loginMerchant = useCallback(
		async (email, password) => {
			try {
				const response = await api.post('/merchant/login', {
					email,
					password,
				});
				const { merchantId, accessToken } = response.data.data;

				await updateUserState(merchantId, accessToken);

				await checkAndSetProfileCompletion(merchantId);
				const onboardingStatus = await api.get(
					`/merchant/${merchantId}/check`,
				);

				if (onboardingStatus.data.data.emailVerified) {
					if (onboardingStatus.data.data.storeSetUp) {
						await getUserData(merchantId);
						navigate(paths.HOME);
						toast.success('Login Successful');
					} else {
						navigate(paths.BUSINESS);
						toast.info('Please complete your store setup');
					}
				} else {
					navigate(paths.VERIFY);
					toast.info('Please verify your email');
				}

				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[updateUserState, checkAndSetProfileCompletion, getUserData, navigate],
	);

	const logoutUser = useCallback(() => {
		dispatch(clearAuth());
		dispatch(clearUserData());
		navigate(paths.HOME);
	}, [dispatch, navigate]);

	const verifyOTP = useCallback(
		async (email, otp) => {
			try {
				const response = await api.post('/merchant/verify-email', {
					email,
					otp,
				});
				const { merchantId, accessToken } = response.data.data;

				await updateUserState(merchantId, accessToken);

				await checkAndSetProfileCompletion(merchantId);

				navigate('/create-business');
				toast.success('Verification Successful');

				return response;
			} catch (error) {
				throw error;
			}
		},
		[updateUserState, checkAndSetProfileCompletion, navigate],
	);

	const resendOTP = useCallback(async (email) => {
		try {
			const response = await api.post('/merchant/resend-otp', {
				email,
			});
			toast.success('OTP resent successfully!');
			return response.data;
		} catch (error) {
			throw error;
		}
	}, []);

	const value = {
		signupMerchant,
		loginMerchant,
		logoutUser,
		verifyOTP,
		resendOTP,
		refreshUserData,
		getUserData,
		checkAndSetProfileCompletion,
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
