// import { toast } from "react-toastify";
import { toast } from 'sonner';
const handleCopyToClipboard = async (text, value) => {
	if (value) {
		try {
			await navigator.clipboard.writeText(value);
			toast.success(`${text}: ${value}`);
		} catch (err) {
			toast.error('Failed to copy to clipboard. Please try again.');
		}
	} else {
		toast.error('No value to copy.');
	}
};

export default handleCopyToClipboard;
