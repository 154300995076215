import { paths } from '../../routes';

export const navItems = [
	{
		title: 'Overview',
		activeIcon: (
			<i
				className='fi fi-sr-chart-pie-alt inline-flex items-center justify-center h-auto'
				style={{ color: '#ffffff' }}></i>
		),
		inactiveIcon: (
			<i
				className='fi fi-br-chart-pie-alt inline-flex items-center justify-center h-auto'
				style={{ color: '#7B8783' }}></i>
		),
		to: paths.OVERVIEW,
	},
	{
		title: 'Store',
		activeIcon: (
			<i
				className='fi fi-sr-store-alt inline-flex items-center justify-center h-auto'
				style={{ color: '#ffffff' }}></i>
		),
		inactiveIcon: (
			<i
				className='fi fi-rr-store-alt inline-flex items-center justify-center h-auto'
				style={{ color: '#7B8783' }}></i>
		),
		to: paths.STORE,
	},
	{
		title: 'Orders',
		activeIcon: (
			<i
				className='fi fi-sr-grocery-basket inline-flex items-center justify-center h-auto'
				style={{ color: '#ffffff' }}></i>
		),
		inactiveIcon: (
			<i
				className='fi fi-rr-grocery-basket inline-flex items-center justify-center h-auto'
				style={{ color: '#7B8783' }}></i>
		),
		to: paths.ORDERS,
	},
	{
		title: 'Customers',
		activeIcon: (
			<i
				className='fi fi-sr-users inline-flex items-center justify-center h-auto'
				style={{ color: '#ffffff' }}></i>
		),
		inactiveIcon: (
			<i
				className='fi fi-rr-users inline-flex items-center justify-center h-auto'
				style={{ color: '#7B8783' }}></i>
		),
		to: paths.CUSTOMERS,
	},
];
