/* global dataLayer */
import React, { Suspense, useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import { paths } from './routes';
import { AuthProvider } from './apis/auth_context';
import { Provider } from 'react-redux';
import { store } from './store';
import { ApiProvider } from './apis/api_context';
import ProtectedRoutes from './newUI/merchant/auth/protected_routes';
import Overview from './newUI/merchant/pages/overview';
import Store from './newUI/merchant/pages/store';
import ProductDetailsPage from './newUI/merchant/pages/productDetails';
import Orders from './newUI/merchant/pages/orders';
import Customers from './newUI/merchant/pages/customers';
import ManageProfile from './newUI/merchant/pages/profile';
import LinearProgress from '@mui/material/LinearProgress';

const LazySignup = React.lazy(() =>
	import('./newUI/merchant/auth/CreateAccount'),
);
const LazySecure = React.lazy(() =>
	import('./newUI/merchant/auth/SecureAccount'),
);
const LazyVerify = React.lazy(() =>
	import('./newUI/merchant/auth/VerifyAccount'),
);
const LazyCreateStore = React.lazy(() =>
	import('./newUI/merchant/auth/CreateBusiness'),
);
const LazySignIn = React.lazy(() => import('./newUI/merchant/auth/signin'));
const LazyPage404 = React.lazy(() => import('./404'));

const App = ({ ws }) => {
	const [isMobile, setIsMobile] = useState(false);
	const googleTageId = process.env.REACT_APP_GOOGLE_TAG_ID;

	useEffect(() => {
		const userAgent =
			navigator.userAgent || navigator.vendor || window.opera;
		if (/android|iPhone|iPod|iPad/i.test(userAgent)) {
			setIsMobile(true);
		}
	}, []);

	useEffect(() => {
		const gtagScript = document.createElement('script');
		gtagScript.async = true;
		gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTageId}`;
		document.head.appendChild(gtagScript);

		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', googleTageId, {
			page_path: window.location.pathname,
		});
	}, [googleTageId]);

	useEffect(() => {
		if (window.gtag) {
			window.gtag('config', googleTageId, {
				page_path: window.location.pathname,
			});
		}
	}, [googleTageId]);

	if (isMobile) {
		return (
			<div className='flex justify-center items-center h-screen'>
				<p className='text-center text-xl'>
					This app is only accessible via web browsers on desktop or
					tablet devices.
				</p>
			</div>
		);
	}

	return (
		<Provider store={store}>
			<Router basename=''>
				<AuthProvider>
					<ApiProvider>
						<Suspense fallback={<LinearProgress />}>
							<Routes>
								{/* Default Route */}
								<Route
									path='/'
									element={
										<Navigate
											to={paths.OVERVIEW}
											replace
										/>
									}
								/>
								{/* Auth Routes */}
								<Route
									path={paths.SIGNUP}
									element={<LazySignup />}
								/>
								<Route
									path={paths.SECURE}
									element={<LazySecure />}
								/>
								<Route
									path={paths.VERIFY}
									element={<LazyVerify />}
								/>
								<Route
									path={paths.BUSINESS}
									element={<LazyCreateStore />}
								/>
								<Route
									path={paths.SIGNIN}
									element={<LazySignIn />}
								/>
								{/* Protected Routes */}
								<Route element={<ProtectedRoutes />}>
									<Route
										path={paths.PROFILE}
										element={<ManageProfile />}
									/>
									<Route
										path={paths.OVERVIEW}
										element={<Overview />}
									/>
									<Route
										path={paths.STORE}
										element={<Store />}
									/>
									<Route
										path={paths.PRODUCTS}
										element={<Store />}
									/>
									<Route
										path={paths.PRODUCTDETAILS}
										element={<ProductDetailsPage />}
									/>
									<Route
										path={paths.COUPONS}
										element={<Store />}
									/>
									<Route
										path={paths.CATEGORIES}
										element={<Store />}
									/>
									<Route
										path={paths.ORDERS}
										element={<Orders />}
									/>
									<Route
										path={paths.ACTIVEORDERS}
										element={<Orders />}
									/>
									<Route
										path={paths.ORDERHISTORY}
										element={<Orders />}
									/>
									<Route
										path={paths.CUSTOMERS}
										element={<Customers />}
									/>
									<Route
										path={paths.ALLCUSTOMERS}
										element={<Customers />}
									/>
								</Route>
								{/* 404 Route */}
								<Route
									path='*'
									element={<LazyPage404 />}
								/>
							</Routes>
						</Suspense>
					</ApiProvider>
				</AuthProvider>
			</Router>
		</Provider>
	);
};

export default App;
