import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSideNav: 'Overview', 
  activeTab: null,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActiveSideNav: (state, action) => {
      state.activeSideNav = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveSideNav, setActiveTab } = navigationSlice.actions;
export default navigationSlice.reducer;
