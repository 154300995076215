import React, { Component } from 'react';

class ErrorBoundary extends Component {
	state = {
		hasError: false,
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true, error, errorInfo });
		// // console.error('Error:', error);
		// // console.error('Error Info:', errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div>Something went wrong. Please try again later.</div>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
