import React from "react";

const Avatar = ({ firstName, lastName, imageSrc, size = 40 }) => {
  const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
  
  return (
    <div
      className="flex items-center justify-center rounded-full bg-[#F0F0F0]"
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={`${initials}`}
          className="rounded-full object-cover"
          style={{ height: '100%', width: '100%' }}
        />
      ) : (
        <span className="text-[#333333] text-md font-semibold" style={{ fontSize: `${size / 2}px` }}>
          {initials}
        </span>
      )}
    </div>
  );
};

export default Avatar;
