import React from 'react';
import shoppingCart from '../../../assets/images/shopping-cart.svg';
import OverviewBox from './../components/overviewBox';
import ActiveOrders from './../components/activeOrders';
import arrowUpIcon from '../../../assets/images/arrow-up.svg';
import arrowDownIcon from '../../../assets/images/arrow-down.svg';
import handleCopyToClipboard from '../../../utils/copyToClipboard';
import { useSelector } from 'react-redux';
import Layout from '../../../Layout';
import { MapIcon, LinkIcon } from 'lucide-react';

const Overview = () => {
	const { analytics, store, orders } = useSelector((state) => state.user);

	const {
		totalSalesValue = 0,
		salesValueGrowth,
		returnedDeliveriesGrowth,
		returnedDeliveriesVolume,
		totalActiveOrders,
		totalsalesVolumeGrowth,
		// orders: activeOrders = [],
	} = analytics || {};
	const activeOrders = orders.filter(
		(order) => order.currentStatus === 'Order Ready',
	);
	// // console.log(activeOrders);

	const getGrowthProps = (growthRate) => ({
		textColor: growthRate > 0 ? 'text-basegreen' : 'text-[#A06841]',
		icon: growthRate > 0 ? arrowUpIcon : arrowDownIcon,
		isPositive: growthRate > 0,
	});

	return (
		<Layout>
			<div className='no-scrollbar font-firma scroll-smooth space-y-8 w-full'>
				<div className='flex gap-4 flex-wrap'>
					{store?.location?.address && (
						<StoreInfo
							icon={<MapIcon size={16} />}
							text={store.location.address}
							label='Your store address'
							onCopy={() =>
								handleCopyToClipboard(
									'Store Address Copied',
									store.location.address,
								)
							}
						/>
					)}
					{store?.storeLink && (
						<StoreInfo
							icon={<LinkIcon size={16} />}
							text={store.storeLink}
							label='Your store link'
							onCopy={() =>
								handleCopyToClipboard(
									'Store Link Copied',
									store.storeLink,
								)
							}
						/>
					)}
				</div>

				<div className='flex flex-wrap gap-4 mb-[32px]'>
					<div className='w-1/2 h-[40vh] bg-basegreen flex flex-1 flex-col justify-between rounded-[12px] p-5'>
						<div className='flex flex-col gap-1'>
							<h2 className='font-[600] text-[21px] leading-[32px] text-white -tracking-[3%]'>
								Active Orders
							</h2>
							<p className='text-[16px] leading-[24px] -tracking-[3%] font-[300] text-[#C4D3CA]'>
								Orders in different stages of fulfilment
							</p>
						</div>
						<h1 className='font-[600] text-[48px] leading-[72px] text-white -tracking-[3%]'>
							{totalActiveOrders}
						</h1>
					</div>
					{activeOrders.length > 0 ? (
						<div className=' w-1/2 h-[40vh]'>
							<ActiveOrders activeOrders={activeOrders} />
							{/* {totalActiveOrders} */}
						</div>
					) : (
						<div className='w-1/2 h-[40vh] bg-white border border-borderNeutral p-4 rounded-[12px] flex flex-1 flex-col gap-3 justify-center items-center'>
							<img
								src={shoppingCart}
								alt='No active orders icon'
								className='w-[80px] h-[80px]'
							/>
							<p className='w-[152px] font-[500] text-[16px] leading-[18px] text-center text-gray-500 -tracking-[3%]'>
								You currently have no active orders
							</p>
						</div>
					)}
				</div>

				<div>
					<h2 className='text-gray-500 font-[500] text-[24px] leading-[36px] -tracking-[3%]'>
						Overview
					</h2>
					<div className='flex gap-4 mt-4'>
						<OverviewBox
							name='Total Sales'
							amount={totalSalesValue}
							growthRate={salesValueGrowth}
							currency='₦'
							{...getGrowthProps(salesValueGrowth)}
						/>
						<OverviewBox
							name='Completed Deliveries'
							amount={totalsalesVolumeGrowth}
							growthRate={totalsalesVolumeGrowth}
							{...getGrowthProps(totalsalesVolumeGrowth)}
						/>
						<OverviewBox
							name='Returned Deliveries'
							amount={returnedDeliveriesVolume}
							growthRate={returnedDeliveriesGrowth}
							{...getGrowthProps(returnedDeliveriesGrowth)}
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const StoreInfo = ({ icon, text, label, onCopy }) => (
	<div className='flex flex-col gap-2 '>
		<h3 className='text-[14px] text-gray-500 font-medium ml-1'>{label}</h3>
		<button
			onClick={onCopy}
			className='min-w-[10vw] max-w-[25vw] flex items-center gap-2 border border-gray-200 p-3 pr-5 rounded-full overflow-hidden'>
			{icon}
			<span className='truncate font-medium'>{text}</span>
		</button>
	</div>
);

export default Overview;
