import React, { useState, useRef, useEffect } from 'react';
import { FaBell, FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import { Drawer } from 'antd';
import Button from './button';
import Dropdown from './dropdown';
import SettingsModal from './settingsModal';
import ProductModal from './productflow/productModal';
import { useAPI } from '../../../apis/api_context';
import { FaGear } from 'react-icons/fa6';
import NotificationModal from './notificationModal';
import SubscriptionBanner from './subscriptionBanner';
import { toast } from 'sonner';

const Header = ({
	pageTitle,
	merchantData,
	refreshUser,
	isProductModalOpen,
	setIsProductModalOpen,
	subscription,
	refreshUserData,
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
	const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [isNotificationModalOpen, setIsNotificationModalOpen] =
		useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResults, setSearchResults] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] =
		useState(false);

	const searchInputRef = useRef(null);
	const apiService = useAPI();

	useEffect(() => {
		if (searchValue.trim()) {
			const fetchSearchResults = async () => {
				setLoading(true);
				try {
					const results = await apiService.searchMerchantData(
						searchValue,
					);
					setSearchResults(results);
				} catch (error) {
					setSearchResults(null);
				} finally {
					setLoading(false);
				}
			};

			const delayDebounceFn = setTimeout(fetchSearchResults, 300);
			return () => clearTimeout(delayDebounceFn);
		} else {
			setSearchResults(null);
		}
	}, [searchValue, apiService]);

	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
	const toggleCreateDropdown = () =>
		setIsCreateDropdownOpen(!isCreateDropdownOpen);
	const toggleSettingsModal = () =>
		setIsSettingsModalOpen(!isSettingsModalOpen);
	const handleOpenSearchModal = () => {
		setIsSearchModalOpen(true);
		setTimeout(() => searchInputRef.current?.focus(), 100);
	};

	const handleCloseSearchModal = () => {
		setIsSearchModalOpen(false);
		setSearchValue('');
		setSearchResults(null);
	};

	const handleOpenNotificationsModal = async () => {
		setIsNotificationModalOpen(true);
	};

	const renderResults = () => {
		if (loading) return <div>Loading...</div>;
		if (!searchResults || Object.keys(searchResults).length === 0)
			return <div>No results found.</div>;

		return (
			<div>
				{Object.entries(searchResults).map(([key, results]) => {
					if (results.length > 0) {
						return (
							<div key={key}>
								<h3>{key.toUpperCase()}</h3>
								<ul>
									{results.map((item, index) => (
										<li key={index}>
											<pre>
												{JSON.stringify(item, null, 2)}
											</pre>
										</li>
									))}
								</ul>
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};

	return (
		<>
			<header className='bg-white border-b'>
				<div className='hidden md:flex justify-between items-center p-4'>
					<h1 className='text-lg font-semibold'>{pageTitle}</h1>
					<div className='flex items-center gap-4'>
						<SubscriptionBanner
							tier={subscription.tier}
							onClick={() => setIsSubscriptionModalOpen(true)}
						/>

						<div className='relative'>
							<Button
								type='primary'
								label='Create'
								onClick={toggleCreateDropdown}
								iconLeft={<FaPlus />}
							/>
							{isCreateDropdownOpen && (
								<Dropdown
									actions={[
										{
											label: 'Create New Product',
											onClick: () =>
												setIsProductModalOpen(true),
										},
									]}
									onClose={toggleCreateDropdown}
									position='right-0 mt-2'
									width='w-[200px]'
								/>
							)}
						</div>

						<div className='flex'>
							<Button
								type='tertiary'
								label={<FaSearch size={20} />}
								onClick={handleOpenSearchModal}
							/>

							<div className='relative'>
								<Button
									type='tertiary'
									label={<FaBell size={20} />}
									onClick={handleOpenNotificationsModal}
								/>
								{isNotificationModalOpen && (
									<NotificationModal
										position='right-0 top-14'
										onClose={() =>
											setIsNotificationModalOpen(false)
										}
									/>
								)}
							</div>
							<Button
								type='tertiary'
								label={<FaGear size={20} />}
								onClick={toggleSettingsModal}
							/>
						</div>
					</div>
				</div>

				<Drawer
					anchor='left'
					open={isSidebarOpen}
					onClose={toggleSidebar}
				/>

				{isSearchModalOpen && (
					<div
						className='fixed inset-0 z-50 flex items-center justify-center bg-primary bg-opacity-70'
						onClick={handleCloseSearchModal}>
						<div
							className='bg-white w-[35vw] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col rounded-[12px]'
							onClick={(e) => e.stopPropagation()}>
							<input
								type='text'
								ref={searchInputRef}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Type to search...'
								className='p-4 border-b'
								autoFocus
							/>
							<div className='p-4'>{renderResults()}</div>
						</div>
					</div>
				)}

				{isSettingsModalOpen && (
					<SettingsModal
						closeModal={toggleSettingsModal}
						refreshUser={refreshUser}
					/>
				)}

				{isProductModalOpen && (
					<ProductModal
						closeProductModal={() => setIsProductModalOpen(false)}
						merchantData={merchantData}
						refreshUser={refreshUser}
					/>
				)}
			</header>
			<SubscriptionModal
				isOpen={isSubscriptionModalOpen}
				onClose={() => setIsSubscriptionModalOpen(false)}
				currentTier={subscription.tier}
				refreshUserData={refreshUserData}
			/>
		</>
	);
};
const PricingCard = ({
	tier,
	price,
	features,
	isRecommended,
	isCurrentPlan,
	ctaText,
	ctaAction,
	highlight = false,
}) => {
	const getBadgeStyles = () => {
		if (isCurrentPlan) return 'text-gray-500';
		if (highlight) return 'text-basegreen';
		return 'text-basegreen ';
	};

	const getButtonStyles = () => {
		if (isCurrentPlan) return 'bg-gray-200 cursor-not-allowed';
		if (highlight) return 'bg-basegreen hover:bg-[#0B6E4FE5] text-white';
		return 'bg-white border-2 border-black hover:bg-gray-50';
	};

	return (
		<div className='bg-gray-50 rounded-xl p-6 flex flex-col relative overflow-hidden'>
			<div className='flex justify-between items-center'>
				<p className={`text-sm font-medium ${getBadgeStyles()}`}>
					{tier.toUpperCase()}
				</p>
				{isRecommended && highlight && (
					<div className='bg-basegreen text-white px-3 py-1 rounded-full text-sm'>
						Recommended
					</div>
				)}
			</div>

			<div className='mb-8'>
				{price !== null && price !== undefined && (
					<div className='mt-4 flex items-end '>
						<p className='text-sm mr-1'>₦</p>
						<p className='text-[28px] font-semibold leading-none'>
							{price.toLocaleString()}{' '}
						</p>
						<span className='text-gray-600 ml-2'>/month</span>
					</div>
				)}
			</div>

			<div className='flex-grow space-y-3 mb-12'>
				{features.map((feature, index) => (
					<div
						key={index}
						className='flex items-center text-gray-500'>
						<span
							className={`mr-2 ${
								highlight ? 'text-basegreen' : ''
							}`}>
							✓
						</span>
						{feature}
					</div>
				))}
			</div>

			<button
				onClick={ctaAction}
				className={`w-full py-4  rounded-full font-medium transition-colors ${getButtonStyles()}`}
				disabled={isCurrentPlan}>
				{ctaText}
			</button>
		</div>
	);
};

const SubscriptionModal = ({
	isOpen,
	onClose,
	currentTier,
	refreshUserData,
}) => {
	const apiService = useAPI();
	const features = {
		free: [
			'Sell up to 100 products',
			'Basic business reports',
			'Free online store',
		],
		standard: [
			'Sell up to 500 products',
			'Business alerts',
			'Create coupons',
			'Email & SMS campaigns',
			'Priority support',
			'24/7 monitoring',
			'Real-time tracking',
		],
		premium: [
			'Unlimited products',
			'Team management',
			'Bulk uploads',
			'Multiple locations',
			'API access',
			'Dedicated manager',
			'All Standard features',
		],
	};

	const handleSubscriptionChange = async (plan) => {
		try {
			// Define the current tier
			const currentTierLower = currentTier.toLowerCase();

			if (currentTierLower === 'premium') {
				// If current plan is Premium, user can downgrade to Free or Standard
				if (plan === 'Free' || plan === 'Standard') {
					console.log('Downgrading from Premium...');
					const response = await apiService.downgradeSubscription(
						plan,
					);
					console.log(response);
				} else {
					console.log('Already on Premium');
					toast('Already on Premium');
				}
			} else if (currentTierLower === 'standard') {
				// If current plan is Standard, user can downgrade to Free or upgrade to Premium
				if (plan === 'Free') {
					console.log('Downgrading from Standard to Free...');
					const response = await apiService.downgradeSubscription(
						'Free',
					);
					console.log(response);
					toast.success('Plan Downgraded to Free');
				} else if (plan === 'Premium') {
					console.log('Upgrading from Standard to Premium...');
					const response = await apiService.upgradeSubscription(
						'Premium',
					);
					window.open(response.paymentUrl, '_blank');
				} else {
					console.log('Already on Standard');
					toast('Already on Standard');
				}
			} else if (currentTierLower === 'free') {
				// If current plan is Free, user can upgrade to Standard or Premium
				if (plan === 'Standard') {
					console.log('Upgrading from Free to Standard...');
					const response = await apiService.upgradeSubscription(
						'Standard',
					);
					window.open(response.paymentUrl, '_blank');
				} else if (plan === 'Premium') {
					console.log('Upgrading from Free to Premium...');
					const response = await apiService.upgradeSubscription(
						'Premium',
					);
					window.open(response.paymentUrl, '_blank');
				} else {
					console.log('Already on Free');
					toast('Already on Free');
				}
			}

			// Refresh user data after subscription change
			refreshUserData();
			onClose();
		} catch (error) {
			console.error('Subscription change failed:', error);
		}
	};

	const plans = [
		{
			tier: 'Free',
			price: 0,
			features: features.free,
			isCurrentPlan: currentTier === 'Free',
			ctaText:
				currentTier === 'Free' ? 'Current Plan' : 'Downgrade to Free',
			ctaAction: () => {
				if (currentTier !== 'Free') handleSubscriptionChange('Free');
			},
		},
		{
			tier: 'Standard',
			price: 4999,
			features: features.standard,
			isRecommended: currentTier === 'Free',
			highlight: true,
			isCurrentPlan: currentTier === 'Standard',
			ctaText:
				currentTier === 'Standard'
					? 'Current Plan'
					: currentTier === 'Premium'
					? 'Downgrade to Standard'
					: 'Upgrade to Standard',
			ctaAction: () => {
				if (currentTier !== 'Standard')
					handleSubscriptionChange('Standard');
			},
		},
		{
			tier: 'Premium',
			price: 9499,
			features: features.premium,
			isRecommended: currentTier === 'Standard',
			isCurrentPlan: currentTier === 'Premium',
			ctaText:
				currentTier === 'Premium'
					? 'Current Plan'
					: 'Upgrade to Premium',
			ctaAction: () => {
				if (currentTier !== 'Premium')
					handleSubscriptionChange('Premium');
			},
		},
	];

	if (!isOpen) return null;

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto'
			onClick={onClose}>
			<div
				className='bg-white rounded-2xl w-full max-w-6xl mx-4 relative'
				onClick={(e) => e.stopPropagation()}>
				<button
					onClick={onClose}
					className='absolute right-4 top-4 text-gray-500 hover:text-gray-700 text-xl'>
					<FaTimes />
				</button>

				<div className='text-center px-6 pt-12 pb-8'>
					<h2 className='text-[21px] font-medium mb-1'>
						Choose the right plan for your business
					</h2>
					<p className='text-gray-500 max-w-2xl mx-auto'>
						Scale your business with features that match your growth
						stage
					</p>
				</div>

				<div className='grid grid-cols-1 md:grid-cols-3 gap-6 p-6'>
					{plans.map((plan, index) => (
						<PricingCard
							key={index}
							{...plan}
						/>
					))}
				</div>

				<div className='text-center px-6 py-8 bg-gray-50 mt-6 rounded-b-2xl flex flex-col items-center justify-center'>
					<p className='text-gray-600'>
						Need help choosing? Let's discuss your business needs
					</p>
					<Button
						type='tertiary'
						label={'Talk to sales'}
					/>
				</div>
			</div>
		</div>
	);
};

export default Header;
