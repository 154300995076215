import React from 'react';

const statusStyles = {
	'Order Created': 'bg-[#F5F5F5] text-[#333333]',
	'Order Ready': 'bg-[#E6F7F7] text-[#38A3A5]',
	'Delivery Pending': 'bg-[#FFF2E8] text-[#F9A825]',
	'Delivery Accepted': 'bg-[#E6F7F7] text-[#43A047]',
	'Order Picked Up': 'bg-[#FFF2E8] text-[#8A6906]',
	'Order Out for Delivery': 'bg-[#E6F7F7] text-[#007BFF',
	'Order Delivered': 'bg-[#E6F7F7] text-[#28A745]',
	'Order Returned': 'bg-[#FDEEEF] text-[#DC3545]',
	'Order Return In Transit': 'bg-[#FFF2E8] text-[#684E00]',
	'Order Return Received': 'bg-[#E6F7F7] text-[#28A745]',
	'Order Canceled': 'bg-[#F5F5F5] text-[#6C757D]',
	'Order Failed Delivery': 'bg-[#FDEEEF] text-[#DC3545]',
};

const StatusChip = ({ status }) => {
	return (
		<div
			className={`inline-block px-4 py-2 rounded-full ${statusStyles[status]}`}>
			{status}
		</div>
	);
};

export default StatusChip;
